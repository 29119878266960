import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Button } from 'reactstrap';
import compareVersions from 'compare-versions';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ReactComponent as RightArrow } from 'assets/icons/chevron-right.svg';
import { PATH_REMOVE_ACCOUNT_REQUEST } from '../paths';
import { withLocale } from '../../../../TranslatorContext';

const RemoveAccountButton = ({ loading, t }) => {
    const [showRemoveAccountButton, setShowRemoveAccountButton] = useState();
    const mobileApp = JSON.parse(global.localStorage.getItem('mobileApp'));

    const handleShowRemoveAccountButton = () => {
        if (mobileApp && mobileApp.os) {
            if (mobileApp.os.startsWith('ios')) {
                if (compareVersions(mobileApp.version, '1.5.10') >= 0) {
                    return true;
                }
                return false;
            }
            if (mobileApp.os.startsWith('android')) {
                if (Number(mobileApp.version) >= 11939) {
                    return true;
                }
                return false;
            }
        }
        return true;
    };
    useEffect(() => {
        setShowRemoveAccountButton(handleShowRemoveAccountButton());
    }, []);

    if (loading || !showRemoveAccountButton) {
        return '';
    }
    return (
        <Container>
            <Row>
                <Col xs="12" md={{ size: 8, offset: 2 }}>
                    <h2>{t('settings/remove-account')}</h2>
                    <Button
                        color="arrow"
                        className="w-100"
                        tag={Link}
                        to={PATH_REMOVE_ACCOUNT_REQUEST}
                    >
                        {t('button/remove-account')}
                        <span className="SVGInline">
                            <RightArrow />
                        </span>
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

RemoveAccountButton.propTypes = {
    loading: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
};

export default withLocale(RemoveAccountButton);
